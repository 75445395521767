import "./App.css";
import Home from "./component/home";
// import "./assets/css/normalize.css";
// import "./assets/css/responsive.css";
import "./assets/css/style.css";
function App() {
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
