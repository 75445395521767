import React from "react";
import logo from "../assets/images/icons/logo.2fda26bd.png";
import blockChain from "../assets/images/block-chain.png";
import AboutDark from "../assets/images/About Dark_.png";
import chart from "../assets/images/chart.png";
import roadMap from "../assets/images/Road Map.png";
import certikLogo from "../assets/images/icons/certik-logotype-h-w.svg";
import bscScan from "../assets/BscScan-logo-circle.webp";

import { useState } from "react";
import copy from 'copy-to-clipboard';

// import "../assets/css/normalize.css";
// import "../assets/css/responsive.css";
import "../assets/css/normalize.css";
import "../assets/css/responsive.css";
import "../assets/css/bootstrap.min.css";
import "../assets/css/animate.min.css";
import "../assets/css/style.css";
import WhitePaper from "../assets/pdf/white_paper.pdf"
import AuditReport from "../assets/pdf/Wire_Swap_Security_Assessment.pdf"
const Home = () => {
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const openCloseMenu = () => {
    console.log("openCloseMenu", open);

    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };
  console.log("open", open);
  const setCopy = () => {
    setShow(true);
    setTimeout(() => {
      setShow(false)
    }, 1500);
  }
  return (
    <>
      <header
        style={{ backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)" }}
      >
        <div class="container-fluid">
          {/* Main Header  */}
          <div class="main-header">
            <nav id="navbar-scroller" class="navbar navbar-light w-100">
              <div class="nav-container-fluid w-100">
                <div
                  class="brand-nav wow bounceInDown"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDuration: "2s",
                    animationDelay: "0s",
                    animationName: "bounceInDown",
                  }}
                >
                  <a>
                    <img
                      //   src="images/icons/logo.2fda26bd.png"
                      src={logo}
                      alt=""
                      style={{
                        height: "50px",
                      }}
                    />
                  </a>
                </div>
                <div
                  class="nav-content-items wow bounceInDown"
                  data-wow-duration="2s"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDuration: "2s",
                    animationDelay: "1",
                    animationName: "bounceInDown",
                  }}
                //   style="visibility: visible; animation-duration: 2s; animation-delay: 1s; animation-name: bounceInDown;"
                >
                  <ul class=" nav m-0 p-0">
                    <li class="nav-item">
                      <a class="nav-link" href="#wire">
                        Overview
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href={WhitePaper}
                        target="_blank"
                      >
                        Whitepaper
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        href={AuditReport}
                        target="_blank"
                      >
                        Audit Report
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#scrollspyTokenomics">
                        Tokenomics
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link active" href="#scrollspyRoadmap">
                        Roadmap
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="https://app.wireswap.io/#/swap">Launch App</a>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link" href="https://multiwireswap.io/en/swap">Multichain App</a>
                    </li> */}
                    <li class="hero-content">

                    <button style={{padding:'4px'}}>  
                        <a
                          href="https://multiwireswap.io/en/swap"
                          className="av"
                          style={{
                            color: "#fff !important",
                            textDecoration: "none",
                          }}
                        >
                          Multichain App
                        </a>
                      </button>
                    </li>

                    
                  </ul>
                </div>
              </div>
              {/* Mobile Header  */}
              <div class="mobile-navbar w-100">
                <nav class="navbar navbar-light w-100">
                  <div class="container-fluid">
                    <div
                      class="brand-nav wow bounceInDown  animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated animated"
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                      style={{
                        visibility: "visible",
                        animationDuration: "2s",
                        animationDelay: "0s",
                        animationName: "bounceInDown",
                      }}
                    //   style="visibility: visible; animation-duration: 2s; animation-delay: 0s; animation-name: bounceInDown;"
                    >
                      <a>
                        <img
                          //   src="images/icons/logo.2fda26bd.png"
                          src={logo}
                          alt=""
                          style={
                            {
                              //   height: "50px",
                            }
                          }
                        />
                      </a>
                    </div>

                    <button
                      class="navbar-toggler wow bounceInDown  animated "
                      data-wow-duration="2s"
                      data-wow-delay="0s"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasNavbar"
                      aria-controls="offcanvasNavbar"
                      style={{
                        visibility: "visible",
                        animationDuration: "2s",
                        animationDelay: "0s",
                        animationName: "bounceInDown",
                        float: "right",
                        zIndex: "1000",
                      }}
                      onClick={openCloseMenu}
                    //   style="visibility: visible; animation-duration: 2s; animation-delay: 0s; animation-name: bounceInDown;"
                    >
                      <span>
                        <i class="fas fa-bars togglerButtton"></i>
                      </span>
                    </button>
                    <div
                      class={open ? "offcanvas  offcanvas-end" : ""}
                      tabindex="-1"
                      id="offcanvasNavbar"
                      aria-labelledby="offcanvasNavbarLabel"
                      style={{ width: "100%" }}
                    >
                      <div class="offcanvas-header bg-dark border-bottom">
                        <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                          Menu
                        </h5>
                        <button
                          type="button"
                          class="text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={openCloseMenu}
                        >
                          <span>
                            <i class="fas fa-times"></i>
                          </span>
                        </button>
                      </div>
                      <div class="offcanvas-body bg-dark">
                        <ul class="m-0 p-0 nav">
                          <li class="nav-item">
                            <a class="nav-link">Overview</a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              href={WhitePaper}
                              target="_blank"
                            >
                              Whitepaper
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#scrollspyTokenomics">
                              Tokenomics
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="#scrollspyRoadmap">
                              Roadmap
                            </a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="https://app.wireswap.io/#/swap">
                              Launch App
                            </a>
                          </li>
                          <li class="hero-content">

<button style={{padding:'4px'}}>  
    <a
      href="https://multiwireswap.io/en/swap"
      className="av"
      style={{
        color: "#fff !important",
        textDecoration: "none",
      }}
    >
      Multichain App
    </a>
  </button>
</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <section>
        <div
          class="scrollspy-area"
          data-bs-spy="scroll"
          data-bs-target="#navbar-scroller"
          data-bs-offset="0"
          tabindex="0"
          style={{ backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)" }}
        >
          {/* Hero Section */}
          <div
            class="hero-area"
            style={{
              backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
            }}
          >
            <div class="py-5"></div>
            <div
              class="container-fluid"
              style={{
                backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
              }}
            >
              <div class="section-container">
                <div class="row" style={{ marginBottom: "19px" }}>
                  <div
                    class="col-lg-6 col-md-7 col-sm-12 wow slideInLeft mt-5 pt-3"
                    data-wow-duration="2s"
                    data-wow-delay="2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "2s",
                      animationDelay: "2s",
                      animationName: "slideInLeft",
                    }}
                  // style="visibility: visible; animation-duration: 2s; animation-delay: 2s; animation-name: slideInLeft;"
                  >
                    <div class="hero-content">
                      <h1>Wireswap Protocol</h1>
                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        Wireswap aims to be a cross-chain decentralized exchange
                        aiming to bring lightspeed swaps at the touch of a
                        button.
                      </p>
                      <button>
                        <a
                          href="https://app.wireswap.io/#/swap"
                          style={{
                            color: "#f9fafb",
                            textDecoration: "none",
                          }}
                        >
                          Purchase Token
                        </a>
                      </button>
                      <a href={WhitePaper} target="_blank">
                        <button>
                          Whitepaper
                        </button>
                      </a>
                    </div>
                    <div class="row py-2 "  >
                      <div className="col"
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <p
                          style={{
                            color: '#fff',
                            textDecoration: 'none',
                            overflow: 'hidden',
                            fontSize: '.8rem',
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: '15px'
                          }}
                        >
                          0x3b3CD14d6D2fc39A68630582c2EB8ec98C21A81e
                        </p>
                      </div>
                      <div className="col"
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}>

                        <i class="fa-solid fa-copy"
                          onClick={() => {
                            setCopy()
                            copy('0x3b3CD14d6D2fc39A68630582c2EB8ec98C21A81e')
                          }}
                          style={{ color: '#fff', fontSize: "24px", padding: '3px 5px', cursor: 'pointer' }}></i>
                        {show && <span style={{ padding: '0px 4px', color: '#fff' }}>Copied</span>
                        } 
                        <a href="https://bscscan.com/address/0x3b3CD14d6D2fc39A68630582c2EB8ec98C21A81e">
                        <img src={bscScan} style={{width:'34px',paddingLeft:'5px'}}/>
                        </a> </div>

                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-5 col-sm-12 wow slideInRight"
                    data-wow-duration="2s"
                    data-wow-delay="2s"
                    style={{
                      visibility: "visible",
                      animationDuration: "2s",
                      animationDelay: "2s",
                      animationName: "slideInRight",
                    }}
                  // style="visibility: visible; animation-duration: 2s; animation-delay: 2s; animation-name: slideInRight;"
                  >
                    <img
                      // src="images/block-chain.png"
                      src={blockChain}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wireswap introduction Section */}
          <div
            class="what-wireswap"
            id="wire"
            style={{
              backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
            }}
          >
            <div class="container-fluid">
              <div class="py-5"></div>
              <div class="section-container">
                <div class="row align-items-center">
                  <div
                    class="col-lg-5 col-md-5 col-sm-12 wow fadeInLeft"
                    data-wow-duration="2s"
                    data-wow-delay="0s"
                    style={{
                      visibility: "visible",
                      animationDuration: "2s",
                      animationDelay: "0s",
                    }}
                  // style="visibility: visible; animation-duration: 2s; animation-delay: 0s;"
                  >
                    <img
                      //  src="images/About Dark_.png"
                      src={AboutDark}
                      alt=""
                    />
                  </div>
                  <div
                    class="col-lg-7 col-md-7 col-sm-12 wow fadeInRight"
                    data-wow-duration="2s"
                    data-wow-delay="0s"
                    style={{
                      visibility: "visible",
                      animationDuration: "2s",
                      animationDelay: "0s",
                    }}
                  // style="visibility: visible; animation-duration: 2s; animation-delay: 0s;"
                  >
                    <div class="what-content">
                      <h1
                        style={{
                          color: "#f9fafb",
                        }}
                      >
                        What is Wireswap Protocol?
                      </h1>
                      <p
                        style={{
                          color: "#f9fafb",
                        }}
                      >
                        Wireswap is a multi chain protocol that aims to
                        facilitate swaps between more than{" "}
                        <label>10,000 tokens</label> on different blockchains
                        such as Ethereum, Binance Smart chain, Solana, Polygon,
                        Fantom, Avalanche, Moonriver in 1 touch.
                        <span>
                          $WIRE is a governance token for Wireswap protocol, all
                          fees and internal accounting will be in $WIRE. Users
                          will need $WIRE to execute swaps between different
                          chains.
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="py-5"></div>
            </div>
          </div>
          {/* Tokenomics Section */}
          <div
            id="scrollspyTokenomics"
            style={{
              backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
            }}
          >
            <div class="container-fluid">
              <div class="section-container">
                <div class="py-4"></div>
                <h1
                  //   style="visibility: visible; animation-duration: 2s; animation-delay: 0s;"
                  style={{
                    visibility: "visible",
                    animationDuration: "2s",
                    animationDelay: "0s",
                  }}
                  data-wow-delay="0s"
                  data-wow-duration="2s"
                  class="section-header wow bounceIn"
                >
                  Tokenomics
                </h1>
                <div class="py-4"></div>
                <div class="row align-items-center">
                  <div class="col-lg-7 col-md-6 col-sm-12">
                    <div class="token-content">
                      <h6
                        class="wow fadeInUp"
                        data-wow-duration="2s"
                        data-wow-delay="1s"
                        style={{
                          visibility: "visible",
                          animationDuration: "2s",
                          animationDelay: "1s",
                          animationName: "none",
                        }}
                      // style="visibility: hidden; animation-duration: 2s; animation-delay: 1s; animation-name: none;"
                      >
                        <span>Max Supply</span> <span>5,000,000</span>
                      </h6>
                      <div class="py-3"></div>
                      <div
                        class="wow slideInLeft"
                        data-wow-duration="2s"
                        data-wow-delay="1s"
                        style={{
                          visibility: "visible",
                          animationDuration: "2s",
                          animationDelay: "1s",
                          animationName: "none",
                        }}
                      // style="visibility: hidden; animation-duration: 2s; animation-delay: 1s; animation-name: none;"
                      >
                        <table class="table table-dark table-striped">
                          <tbody>
                            <tr>
                              <td>Public Sale</td>
                              <td>30%</td>
                            </tr>
                            <tr>
                              <td>Liquidity</td>
                              <td>20%</td>
                            </tr>
                            <tr>
                              <td>Staking Rewards</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>Owners &amp; Developers</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>Advisors</td>
                              <td>10%</td>
                            </tr>
                            <tr>
                              <td>Airdrops</td>
                              <td>5%</td>
                            </tr>
                            <tr>
                              <td>Community Rewards</td>
                              <td>5%</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-6 col-sm-12">
                    <div
                      class="token-chart wow slideInRight"
                      data-wow-duration="2s"
                      data-wow-delay="1s"
                      style={{
                        visibility: "visible",
                        animationDuration: "2s",
                        animationDelay: "1s",
                        animationName: "none",
                      }}
                    //   style="visibility: hidden; animation-duration: 2s; animation-delay: 1s; animation-name: none;"
                    >
                      <img
                        //   src="images/chart.png"
                        src={chart}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="py-5"></div>
          </div>
          {/* Roadmap Section  */}
          <div
            id="scrollspyRoadmap"
            style={{
              backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5);",
            }}
          >
            <div
              class="container-fluid"
              style={{
                backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
              }}
            >
              <div class="roarmap-container">
                <div class="py-4"></div>
                <h1
                  class="section-header wow bounceIn"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDuration: "2s",
                    animationDelay: "0s",
                    animationName: "bounceInDown",
                    color: "rgb(87, 197, 234)",
                  }}
                //   style="visibility: hidden; animation-duration: 2s; animation-delay: 0s; color: rgb(87, 197, 234); animation-name: none;"
                >
                  Roadmap
                </h1>
                <div class="py-4"></div>
              </div>
            </div>
            <img
              class="wow fadeInDown"
              data-wow-duration="2s"
              data-wow-delay="1s"
              //   src="images/Road Map.png"
              src={roadMap}
              alt=""
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "1s",
                animationName: "none",
                backgroundImage:
                  "linear-gradient(90deg, rgb(11, 22, 149), rgb(189, 39, 197)",
              }}
            //   style="visibility: hidden; animation-duration: 2s; animation-delay: 1s; background-image: linear-gradient(90deg, rgb(11, 22, 149), rgb(189, 39, 197)); animation-name: none;"
            />
          </div>
          {/* Audits Section  */}
          <div class="audits">
            <div
              class="container-fluid"
              style={{
                backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
              }}
            >
              <div class="section-container">
                <div class="py-4"></div>
                <h1
                  class="section-header wow bounceIn"
                  data-wow-duration="2s"
                  data-wow-delay="0s"
                  style={{
                    visibility: "visible",
                    animationDuration: "2s",
                    animationDelay: "0s",
                    animationName: "none",
                  }}
                //   style="visibility: hidden; animation-duration: 2s; animation-delay: 0s; animation-name: none;"
                >
                  Audits
                </h1>
                <div class="py-4"></div>
                <img
                  //   src="images/icons/certik-logotype-h-w.svg"
                  src={certikLogo}
                  alt=""
                  class="wow fadeInUp"
                  data-wow-duration="4s"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDuration: "4s",
                    animationDelay: "2s",
                    animationName: "none",
                  }}
                //   style="visibility: hidden; animation-duration: 4s; animation-delay: 1s; animation-name: none;"
                />
                <h5
                  class="comming-soon wow fadeIn"
                  data-wow-duration="4s"
                  data-wow-delay="1s"
                  style={{
                    visibility: "visible",
                    animationDuration: "4s",
                    animationDelay: "1s",
                    animationName: "none",
                    color: "white",
                  }}
                //   style="visibility: hidden; animation-duration: 4s; animation-delay: 1s; color: white; animation-name: none;"
                >
                  <a
                    href={AuditReport}
                    target="_blank"
                    class="text-white"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    Certik audit report
                  </a>
                </h5>
                <div class="py-5"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Audits Section  */}
      <footer
        style={{
          backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
        }}
      >
        <div class="container-fluid">
          <div
            class="section-container"
            style={{
              backgroundImage: "linear-gradient(90deg,#0b1695,#bd27c5)",
            }}
          >
            <h1
              class="section-header wow fadeInUp                 animated"
              data-wow-duration="2s"
              data-wow-delay="0s"
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "0s",
                animationName: "none",
              }}
            //   style="visibility: hidden; animation-duration: 2s; animation-delay: 0s; animation-name: none;"
            >
              Smart Contact
            </h1>
            <div class="py-3"></div>
            <ul class="p-0 m-0 text-center">
              <li
                class="wow bounceInLeft"
                data-wow-duration="2s"
                data-wow-delay="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationDelay: "2s",
                  animationName: "none",
                }}
              // style="visibility: hidden; animation-duration: 2s; animation-delay: 2s; animation-name: none;"
              >
                <a href="https://www.facebook.com/Wireswap-102238962331272">
                  <span>
                    <i
                      class="fab fa-facebook-square"
                      style={{
                        color: "#f9fafb",
                      }}
                    ></i>
                  </span>
                </a>
              </li>
              <li
                class="wow bounceInLeft"
                data-wow-duration="2s"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationDelay: "1s",
                  animationName: "none",
                }}
              // style="visibility: hidden; animation-duration: 2s; animation-delay: 1s; animation-name: none;"
              >
                <a href="https://twitter.com/wireswap">
                  <span>
                    <i
                      class="fab fa-twitter-square"
                      style={{
                        color: "#f9fafb",
                      }}
                    ></i>
                  </span>
                </a>
              </li>
              <li
                class="wow bounceInRight"
                data-wow-duration="2s"
                data-wow-delay="1s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationDelay: "1s",
                  animationName: "none",
                }}
              // style="visibility: hidden; animation-duration: 2s; animation-delay: 1s; animation-name: none;"
              >
                <a href="https://www.reddit.com/user/Wireswap">
                  <span
                    style={{
                      color: "white",
                    }}
                  >
                    <i class="fab fa-reddit"></i>
                  </span>
                </a>
              </li>
              <li
                class="wow bounceInRight"
                data-wow-duration="2s"
                data-wow-delay="2s"
                style={{
                  visibility: "visible",
                  animationDuration: "2s",
                  animationDelay: "2s",
                  animationName: "none",
                }}
              // style="visibility: hidden; animation-duration: 2s; animation-delay: 2s; animation-name: none;"
              >
                <a href="https://t.me/Wireswap">
                  <span>
                    <i
                      class="fab fa-telegram-plane"
                      style={{
                        color: "#f9fafb",
                      }}
                    ></i>
                  </span>
                </a>
              </li>
            </ul>
            <div class="py-2"></div>
            <p
              class="wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="2s"
              style={{
                visibility: "visible",
                animationDuration: "2s",
                animationDelay: "2s",
                animationName: "none",
                color: "rgb(249, 249, 251)",
              }}
            //   style="visibility: hidden; animation-duration: 2s; animation-delay: 2s; color: rgb(249, 249, 251); animation-name: none;"
            >
              ©Copyright Wireswap 2021
            </p>
          </div>
        </div>
      </footer>
      <script
        type="text/jscript"
        src="../assets/js/jquery-3.5.1.min.js"
      ></script>
      <script type="text/jscript" src="../assetsjs/bootstrap.min.js"></script>
      <script type="text/jscript" src="../assetsjs/wow.min.js"></script>
      <script>new WOW().init();</script>
    </>
  );
};

export default Home;
